module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.4_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3_qbvvyqwjyeqo2yjhapoxgruyba/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#fff","theme_color":"#fff","display":"minimal-ui","icon":"src/images/favicon.png","icons":[{"src":"/w-icon-36.png","sizes":"36x36","type":"image/png"},{"src":"/w-icon-48.png","sizes":"48x48","type":"image/png"},{"src":"/w-icon-72.png","sizes":"72x72","type":"image/png"},{"src":"/w-icon-96.png","sizes":"96x96","type":"image/png"},{"src":"/w-icon-144.png","sizes":"144x144","type":"image/png"},{"src":"/w-icon-192.png","sizes":"192x192","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d8e39c9518dc7aecf7545e9b96fc796f"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.4_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3.1_react@18.3.1__react@18.3.1__ksahkdmtt4vg3no4rafugy5cwm/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
